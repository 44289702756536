
@tailwind base;
@tailwind components;
@tailwind utilities;
/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.ant-space-item{
    overflow-wrap: anywhere !important;
} */


.c-line-del{
    background-color: #690303 !important;
}

.c-line-add {
    background-color: #025218 !important;
}

