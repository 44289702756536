@keyframes sidebarMove {
    0% {
        left: -50%;
    }

    100% {
        left: 0;
    }
}

.layout {
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

.header {
    display: none;
}

.sider {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    left: 0;
    top: 0;
}

.sider::-webkit-scrollbar {
    display: none;
}

.main__collap {
    margin-left: 90px;
}

.main__content {
    margin-left: 215px;
}

.order {
    display: flex;
    margin-top: 20px;
}

.order_col {
    min-width: 50%;
}

.order__div {
    display: flex;
    width: 100%;
    padding: 10px;
    background-color: #f4f4f4;
    align-items: center;
    justify-content: center;
}

.offer__div {
    display: flex;
    width: 100%;
    padding: 10px;
    background-color: #f4f4f4;
    align-items: center;
    justify-content: space-evenly;
}

.applied__div {
    background-color: #f4f4f4;
    padding: 10px;
    margin-top: 10px;
}

.button__row {
    display: flex;
}

.order__attach {
    display: flex;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #f4f4f4;
}

.rating {
    display: flex;
    width: 100%;
    padding: 10px 10px;
    background-color: #f4f4f4;
    align-items: center;
}

.rating__gopher {
    display: flex;
    width: 350px;
    height: 150px;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    margin-top: 10px;
    background-color: white;
    border: 1px solid rgb(109, 105, 105);
    margin-right: 20px;
}

.rating__gopher>h2 {
    line-height: 18px;
    font-size: 20px;
    font-weight: 700;
    padding-left: 10px;
}

.counter__offer {
    min-width: 100%;
}

.rating__gopher>h4 {
    font-size: 15px;
    font-weight: 500;
    padding-left: 10px;
}

.Declined {
    border: 1px solid red;
    color: red;
    background-color: white;
}

.Accepted {
    border: 1px solid #0dcaf0;
    color: #0dcaf0;
    background-color: white;
}

.Pending {
    border: 1px solid yellow;
    color: yellow;
    background-color: white;
}

.profile__pic {
    border-radius: 100%;
}

.container {
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.responsive-table li {
    border-radius: 3px;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.table-header {
    background-color: #001529;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.table-row {
    letter-spacing: 0.03em;

    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.col-2 {
    flex-basis: 30%;
    margin-left: 1rem;
}

.col-3 {
    flex-basis: 25%;
    margin-left: 1rem;

}

.col-4 {
    flex-basis: 25%;
}

.col-5 {
    flex-basis: 40%;
}

.col-6 {
    flex-basis: 10%;
}

.modal {
    display: flex;
    width: 520px;
    margin: -23px -24px;
}

.popup {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 50vh;
    justify-content: start;
    align-items: flex-start;
}

.flag__new {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 50vh;
    justify-content: start;
    align-items: flex-start;
    overflow: scroll;
}

.flag__new::-webkit-scrollbar {
    display: none;
}

.counter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 1rem;
    background-color: black;
}

.flag__message {
    width: 200px;
    display: flex;
    padding: 5px;
    background-color: rgb(95, 91, 91);
    justify-content: center;
    align-items: center;
}

.flag__message>h1 {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.counter>h1 {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.message__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 15px;
    background-color: #f4f4f4;
}

.chat__body {
    flex: 1;
    width: 100%;
    padding: 30px 10px;
    overflow: scroll;
    background-color: #f4f4f4;
}

.chat__body::-webkit-scrollbar {
    display: none;
}

.chat__message {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 10px;
    background-color: #4396f5;
    width: fit-content;
    border-radius: 10px;
    color: white;
    margin-bottom: 20px;
}

.chat__name {
    position: absolute;
    top: -17px;
    font-size: x-small;
    color: black;
}

.chat__timestamp {
    font-size: xx-small;
}

.chat__reciever {
    margin-left: auto;
    color: black;
    background-color: #dcf8c6;
}

.flag__desc {
    display: flex;
    flex-direction: column;
    margin: 1px 15px;
}

.flag__desc>h1 {
    font-size: 18px;
    font-weight: 600;
    color: black;
}

.flag__desc>p {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-top: -13px;
}

.no__order {
    font-size: 18px;
    font-weight: 600;
    color: black;
    padding: 5px 25px;
}

.order__total {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 15px;
    background-color: #f4f4f4;
}

.total__charge {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
}

.total__charge>h2 {
    font-size: 17px;
    font-weight: 600;
    color: black;
}

.total__chargeResult {
    display: flex;
    width: 100%;
    padding: 5px 20px;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid black;
}

.total__chargeResult>h2 {
    font-size: 20px;
    font-weight: 600;
    color: #3ca6ff;
}

.hamburger {
    width: 1rem;
    height: 1rem;
    font-weight: 800;
    color: black;
    font-size: 27px;
    margin-right: 1rem;
    transform: translateX(225deg);
    transition: transform 0.6s ease-in-out;
    cursor: pointer;
}

.user__detail {
    display: flex;
    justify-content: space-evenly;
    background-color: #f4f4f4;
}

.user__top {
    width: 25%;
}

.modal__coupon {
    width: 800px;
}



.cogs-false {
    border: 1px solid red;
    color: red;
    background-color: white;
    height: 1.6rem;
}

.cogs-true {
    border: 1px solid #0dcaf0;
    color: #0dcaf0;
    background-color: white;
}

.cogs-null {
    border: 1px solid rgb(255, 234, 0);
    color: rgb(255, 221, 0);
    background-color: white;
}



@media all and (max-width: 920px) {
    .sider {
        display: none;
    }

    .main__content {
        margin-left: 0.3rem;
        margin-top: 3.5rem;
        margin-right: 0.6rem;
    }

    .header {
        display: flex;
        position: fixed;
        align-items: center;
        width: 100vw;
        top: 0;
        background-color: white;
        z-index: 10;
        padding: 10px 20px;
    }

    .header>img {
        width: 100px;
        height: 40px;
        margin-left: 1rem;
    }

    .header__menu {
        position: absolute;
        left: 0;
        top: 3.9rem;
        animation: sidebarMove 300ms;
        transition: transform 0.3s ease-in-out;
    }

    .user__detail {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
    }

    .user__top {
        width: 50%;
    }

}

@media all and (max-width: 767px) {
    .table-header {
        display: none;
    }

    li {
        display: block;
    }

    .col {
        flex-basis: 100%;
    }

    .col {
        display: flex;
        padding: 10px;
    }

    .message__section {
        padding: 5px;
    }

    .user__top {
        width: 50%;
    }

    .container {
        padding: 0px;
        margin-left: -2.3rem;
    }

    .addess__page {
        min-width: 740px;
        overflow-x: scroll;
    }

    .addess__page::-webkit-scrollbar {
        display: none;
    }

    .modal__coupon {
        width: 700px;
    }
}

@media only screen and (max-width: 700px) {
    .order {
        display: flex;
        flex-direction: column;
    }

    .rating {
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .rating__gopher {
        width: 220px;
        height: 180px;
    }

    .offer__div {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .layout {
        min-height: 100vh;
        min-width: 100vw;
        overflow-x: scroll;
    }

    .user__top {
        width: 100%;
    }

    .responsive-table li {
        padding: 7px 8px;
    }

    .modal__coupon {
        width: 90%;
    }
}